@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  @apply h-[4px] w-[4px] bg-white dark:bg-black !important;
}

::-webkit-scrollbar-track {
  @apply bg-white dark:bg-black !important; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  @apply bg-black dark:bg-white !important; /* color of the scroll thumb */
}

:root {
  --color-primary: #000000;
  --font-display: "Comfortaa", sans-serif;
}

.shadow-solid-primary {
  box-shadow: 10px 10px var(--color-primary);
}

.pyro > .before, .pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  -moz-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.pyro > .after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  to {
    box-shadow: 144px -186.6666666667px #ff2200, -44px 66.3333333333px #ff0084, 243px -373.6666666667px #005eff, -187px 61.3333333333px #003cff, 249px 45.3333333333px #d500ff, 158px -110.6666666667px #00ff11, 41px -186.6666666667px #00ccff, -6px -14.6666666667px #00ff26, -89px -201.6666666667px #0dff00, -192px -17.6666666667px #00ff95, 198px 80.3333333333px #ff6f00, 234px 59.3333333333px #b700ff, -37px -255.6666666667px #62ff00, 67px 45.3333333333px #ff003c, -166px -21.6666666667px #00aeff, -205px -266.6666666667px #2b00ff, -34px -195.6666666667px #2200ff, 135px -413.6666666667px #0084ff, -64px -339.6666666667px #ff9100, -172px 43.3333333333px #62ff00, -48px -232.6666666667px #2600ff, -208px -246.6666666667px #ff0080, 13px -26.6666666667px #ff009d, 211px -194.6666666667px #ff00ea, 184px -248.6666666667px #ff6600, -129px -263.6666666667px #00ffe6, 217px -164.6666666667px #a200ff, -228px -96.6666666667px #0055ff, -135px -337.6666666667px #00fffb, -178px -81.6666666667px #eaff00, 105px -247.6666666667px #0040ff, -149px -390.6666666667px #fff700, -97px -305.6666666667px #5e00ff, -172px 79.3333333333px #9d00ff, -201px -143.6666666667px #9dff00, 40px -408.6666666667px #7b00ff, 142px 22.3333333333px #ff0051, 10px -10.6666666667px #00ffcc, 1px -225.6666666667px #00e1ff, 210px 25.3333333333px #ff00d0, 0px -318.6666666667px #0051ff, 239px -337.6666666667px #ff00e6, 23px -146.6666666667px #77ff00, 227px -84.6666666667px #73ff00, -215px -366.6666666667px #9900ff, -215px -44.6666666667px #2bff00, -189px -354.6666666667px #ff0015, -158px 62.3333333333px #ffbf00, -169px -371.6666666667px #ff001e, 2px 33.3333333333px #ff6200, -141px -218.6666666667px #8cff00;
  }
}
@-moz-keyframes bang {
  to {
    box-shadow: 144px -186.6666666667px #ff2200, -44px 66.3333333333px #ff0084, 243px -373.6666666667px #005eff, -187px 61.3333333333px #003cff, 249px 45.3333333333px #d500ff, 158px -110.6666666667px #00ff11, 41px -186.6666666667px #00ccff, -6px -14.6666666667px #00ff26, -89px -201.6666666667px #0dff00, -192px -17.6666666667px #00ff95, 198px 80.3333333333px #ff6f00, 234px 59.3333333333px #b700ff, -37px -255.6666666667px #62ff00, 67px 45.3333333333px #ff003c, -166px -21.6666666667px #00aeff, -205px -266.6666666667px #2b00ff, -34px -195.6666666667px #2200ff, 135px -413.6666666667px #0084ff, -64px -339.6666666667px #ff9100, -172px 43.3333333333px #62ff00, -48px -232.6666666667px #2600ff, -208px -246.6666666667px #ff0080, 13px -26.6666666667px #ff009d, 211px -194.6666666667px #ff00ea, 184px -248.6666666667px #ff6600, -129px -263.6666666667px #00ffe6, 217px -164.6666666667px #a200ff, -228px -96.6666666667px #0055ff, -135px -337.6666666667px #00fffb, -178px -81.6666666667px #eaff00, 105px -247.6666666667px #0040ff, -149px -390.6666666667px #fff700, -97px -305.6666666667px #5e00ff, -172px 79.3333333333px #9d00ff, -201px -143.6666666667px #9dff00, 40px -408.6666666667px #7b00ff, 142px 22.3333333333px #ff0051, 10px -10.6666666667px #00ffcc, 1px -225.6666666667px #00e1ff, 210px 25.3333333333px #ff00d0, 0px -318.6666666667px #0051ff, 239px -337.6666666667px #ff00e6, 23px -146.6666666667px #77ff00, 227px -84.6666666667px #73ff00, -215px -366.6666666667px #9900ff, -215px -44.6666666667px #2bff00, -189px -354.6666666667px #ff0015, -158px 62.3333333333px #ffbf00, -169px -371.6666666667px #ff001e, 2px 33.3333333333px #ff6200, -141px -218.6666666667px #8cff00;
  }
}
@-o-keyframes bang {
  to {
    box-shadow: 144px -186.6666666667px #ff2200, -44px 66.3333333333px #ff0084, 243px -373.6666666667px #005eff, -187px 61.3333333333px #003cff, 249px 45.3333333333px #d500ff, 158px -110.6666666667px #00ff11, 41px -186.6666666667px #00ccff, -6px -14.6666666667px #00ff26, -89px -201.6666666667px #0dff00, -192px -17.6666666667px #00ff95, 198px 80.3333333333px #ff6f00, 234px 59.3333333333px #b700ff, -37px -255.6666666667px #62ff00, 67px 45.3333333333px #ff003c, -166px -21.6666666667px #00aeff, -205px -266.6666666667px #2b00ff, -34px -195.6666666667px #2200ff, 135px -413.6666666667px #0084ff, -64px -339.6666666667px #ff9100, -172px 43.3333333333px #62ff00, -48px -232.6666666667px #2600ff, -208px -246.6666666667px #ff0080, 13px -26.6666666667px #ff009d, 211px -194.6666666667px #ff00ea, 184px -248.6666666667px #ff6600, -129px -263.6666666667px #00ffe6, 217px -164.6666666667px #a200ff, -228px -96.6666666667px #0055ff, -135px -337.6666666667px #00fffb, -178px -81.6666666667px #eaff00, 105px -247.6666666667px #0040ff, -149px -390.6666666667px #fff700, -97px -305.6666666667px #5e00ff, -172px 79.3333333333px #9d00ff, -201px -143.6666666667px #9dff00, 40px -408.6666666667px #7b00ff, 142px 22.3333333333px #ff0051, 10px -10.6666666667px #00ffcc, 1px -225.6666666667px #00e1ff, 210px 25.3333333333px #ff00d0, 0px -318.6666666667px #0051ff, 239px -337.6666666667px #ff00e6, 23px -146.6666666667px #77ff00, 227px -84.6666666667px #73ff00, -215px -366.6666666667px #9900ff, -215px -44.6666666667px #2bff00, -189px -354.6666666667px #ff0015, -158px 62.3333333333px #ffbf00, -169px -371.6666666667px #ff001e, 2px 33.3333333333px #ff6200, -141px -218.6666666667px #8cff00;
  }
}
@-ms-keyframes bang {
  to {
    box-shadow: 144px -186.6666666667px #ff2200, -44px 66.3333333333px #ff0084, 243px -373.6666666667px #005eff, -187px 61.3333333333px #003cff, 249px 45.3333333333px #d500ff, 158px -110.6666666667px #00ff11, 41px -186.6666666667px #00ccff, -6px -14.6666666667px #00ff26, -89px -201.6666666667px #0dff00, -192px -17.6666666667px #00ff95, 198px 80.3333333333px #ff6f00, 234px 59.3333333333px #b700ff, -37px -255.6666666667px #62ff00, 67px 45.3333333333px #ff003c, -166px -21.6666666667px #00aeff, -205px -266.6666666667px #2b00ff, -34px -195.6666666667px #2200ff, 135px -413.6666666667px #0084ff, -64px -339.6666666667px #ff9100, -172px 43.3333333333px #62ff00, -48px -232.6666666667px #2600ff, -208px -246.6666666667px #ff0080, 13px -26.6666666667px #ff009d, 211px -194.6666666667px #ff00ea, 184px -248.6666666667px #ff6600, -129px -263.6666666667px #00ffe6, 217px -164.6666666667px #a200ff, -228px -96.6666666667px #0055ff, -135px -337.6666666667px #00fffb, -178px -81.6666666667px #eaff00, 105px -247.6666666667px #0040ff, -149px -390.6666666667px #fff700, -97px -305.6666666667px #5e00ff, -172px 79.3333333333px #9d00ff, -201px -143.6666666667px #9dff00, 40px -408.6666666667px #7b00ff, 142px 22.3333333333px #ff0051, 10px -10.6666666667px #00ffcc, 1px -225.6666666667px #00e1ff, 210px 25.3333333333px #ff00d0, 0px -318.6666666667px #0051ff, 239px -337.6666666667px #ff00e6, 23px -146.6666666667px #77ff00, 227px -84.6666666667px #73ff00, -215px -366.6666666667px #9900ff, -215px -44.6666666667px #2bff00, -189px -354.6666666667px #ff0015, -158px 62.3333333333px #ffbf00, -169px -371.6666666667px #ff001e, 2px 33.3333333333px #ff6200, -141px -218.6666666667px #8cff00;
  }
}
@keyframes bang {
  to {
    box-shadow: 144px -186.6666666667px #ff2200, -44px 66.3333333333px #ff0084, 243px -373.6666666667px #005eff, -187px 61.3333333333px #003cff, 249px 45.3333333333px #d500ff, 158px -110.6666666667px #00ff11, 41px -186.6666666667px #00ccff, -6px -14.6666666667px #00ff26, -89px -201.6666666667px #0dff00, -192px -17.6666666667px #00ff95, 198px 80.3333333333px #ff6f00, 234px 59.3333333333px #b700ff, -37px -255.6666666667px #62ff00, 67px 45.3333333333px #ff003c, -166px -21.6666666667px #00aeff, -205px -266.6666666667px #2b00ff, -34px -195.6666666667px #2200ff, 135px -413.6666666667px #0084ff, -64px -339.6666666667px #ff9100, -172px 43.3333333333px #62ff00, -48px -232.6666666667px #2600ff, -208px -246.6666666667px #ff0080, 13px -26.6666666667px #ff009d, 211px -194.6666666667px #ff00ea, 184px -248.6666666667px #ff6600, -129px -263.6666666667px #00ffe6, 217px -164.6666666667px #a200ff, -228px -96.6666666667px #0055ff, -135px -337.6666666667px #00fffb, -178px -81.6666666667px #eaff00, 105px -247.6666666667px #0040ff, -149px -390.6666666667px #fff700, -97px -305.6666666667px #5e00ff, -172px 79.3333333333px #9d00ff, -201px -143.6666666667px #9dff00, 40px -408.6666666667px #7b00ff, 142px 22.3333333333px #ff0051, 10px -10.6666666667px #00ffcc, 1px -225.6666666667px #00e1ff, 210px 25.3333333333px #ff00d0, 0px -318.6666666667px #0051ff, 239px -337.6666666667px #ff00e6, 23px -146.6666666667px #77ff00, 227px -84.6666666667px #73ff00, -215px -366.6666666667px #9900ff, -215px -44.6666666667px #2bff00, -189px -354.6666666667px #ff0015, -158px 62.3333333333px #ffbf00, -169px -371.6666666667px #ff001e, 2px 33.3333333333px #ff6200, -141px -218.6666666667px #8cff00;
  }
}
@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-webkit-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-moz-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-o-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-ms-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

.rainbow-bg{
		animation: rainbow-bg 5s linear;
		animation-iteration-count: infinite;
}

@keyframes rainbow-bg{
		100%,0%{
      
      @apply bg-pink-400;
		}
		8%{
			@apply bg-rose-400;
		}
		16%{
      
      @apply bg-orange-300;
		}
		25%{
			@apply bg-yellow-200;
		}
		33%{
      
      @apply bg-pink-200;
		}
		41%{
			@apply bg-orange-300;
		}
		50%{
      
      @apply bg-yellow-200;
		}
		58%{
			@apply bg-rose-400;
		}
		66%{
      
      @apply bg-pink-400;
		}
		75%{
			@apply bg-orange-300;
		}
		83%{
      
      @apply bg-yellow-200;
		}
		91%{
			@apply bg-orange-300;
		}
}

@keyframes rainbow{
		100%,0%{
			color: rgb(255,0,0);
		}
		8%{
			color: rgb(255,127,0);
		}
		16%{
			color: rgb(255,255,0);
		}
		25%{
			color: rgb(127,255,0);
		}
		33%{
			color: rgb(0,255,0);
		}
		41%{
			color: rgb(0,255,127);
		}
		50%{
			color: rgb(0,255,255);
		}
		58%{
			color: rgb(0,127,255);
		}
		66%{
			color: rgb(0,0,255);
		}
		75%{
			color: rgb(127,0,255);
		}
		83%{
			color: rgb(255,0,255);
		}
		91%{
			color: rgb(255,0,127);
		}
}